@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
:root {
  --scroll-bg: 255 255 255;
}

/* mixins */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
fieldset,
legend,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li {
  line-height: 150%;
  margin-bottom: 0px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}

input,
textarea,
select,
.phone-input,
.form-control {
  background: #1f1f1f;
  color: rgba(255, 255, 255, 0.87);
  border: none;
  border-radius: 0.6em;
  padding: 1em 1.5em;
  width: 100%;
  line-height: normal;
  outline: none;
  display: block;
  transition: 0.3s;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder, .phone-input::-moz-placeholder, .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder, .phone-input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
input::placeholder,
textarea::placeholder,
select::placeholder,
.phone-input::placeholder,
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}

input {
  box-shadow: none !important;
}
input[type=number] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}
input[type=radio], input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  background-color: #505050;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  transition: background-color 0.3s;
  border: none;
}
input[type=radio]:checked, input[type=checkbox]:checked {
  background-color: #ff5252;
  background-image: url(../images/icons/mark.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 65%;
}
input[type=radio] {
  border-radius: 50%;
}
input[type=checkbox] {
  border-radius: 0.4em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
label span {
  flex: 1;
  margin-left: 0.6em;
}

fieldset legend {
  font-size: 1em;
  font-weight: 500;
}

.input-group {
  flex-wrap: nowrap;
}
.input-group > *:not(:first-child) {
  margin-left: 1px !important;
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn, .btn-2, .btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em 1em;
  text-align: center;
  transition: 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1em;
  line-height: 150%;
  font-weight: 500;
}
.btn-1 {
  background-color: #373737;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #373737;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 43px;
}
.btn-1:hover, .btn-1:focus, .btn-1:active {
  background-color: #414141;
  color: #fff;
  border: 1px solid #414141;
}
.btn-1:disabled {
  border: 1px solid rgba(31, 31, 31, 0.38);
  background: rgba(31, 31, 31, 0.38);
  color: #8e8e8e;
}
.btn-2 {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #ff5252;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 43px;
}
.btn-2:hover, .btn-2:focus, .btn-2:active {
  background-color: #bf3535;
  color: #fff;
  border: 1px solid #bf3535;
}
.btn-2:disabled {
  border: 1px solid rgba(31, 31, 31, 0.38);
  background: rgba(31, 31, 31, 0.38);
  color: #8e8e8e;
}
.btn-cart {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-cart div {
  font-size: 0.9em;
  font-weight: 700;
  width: 2em;
  height: 2em;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ff5252;
}
.btn-fav {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  width: 1.5em;
  height: 1.5em;
}
.btn-fav .icon, .btn-fav .icon-fill, .btn-fav .icon-stroke {
  font-size: 1.5em;
  position: absolute;
  top: 0px;
  left: 0px;
}
.btn-fav .icon-stroke {
  color: rgba(255, 255, 255, 0.87);
  z-index: 1;
}
.btn-fav .icon-fill {
  color: #ff5252;
  z-index: 2;
  transition: 0.3s;
}

.toggle-btns {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 0.6em;
  display: flex;
  align-items: stretch;
}
.toggle-btns button {
  width: 50%;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  padding: 0.9em;
  border: 1px solid transparent;
  border-radius: 0.6em;
}
.toggle-btns button.active {
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #ff5252;
  background-color: #121212;
}

a svg path {
  stroke: inherit;
}

h1,
.h1 {
  font-size: 2.25em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;
}
@media (max-width: 991px) {
  h1,
.h1 {
    font-size: 2em;
  }
}
@media (max-width: 575px) {
  h1,
.h1 {
    font-size: 1.6em;
  }
}

h2 {
  font-size: 1.75em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;
}
@media (max-width: 575px) {
  h2 {
    font-size: 1.5em;
  }
}

h3 {
  font-size: 1.25em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
}

h4 {
  font-size: 1.25em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}

h5 {
  font-size: 1.1em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0px;
}

h6 {
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.6);
}

.fs-07 {
  font-size: 0.7em;
}
.fs-08 {
  font-size: 0.8em;
}
.fs-09 {
  font-size: 0.9em;
}
.fs-11 {
  font-size: 1.1em;
}
.fs-12 {
  font-size: 1.2em;
}
.fs-13 {
  font-size: 1.3em;
}
.fs-14 {
  font-size: 1.4em;
}
.fs-15 {
  font-size: 1.5em;
}
.fs-16 {
  font-size: 1.6em;
}
.fs-17 {
  font-size: 1.7em;
}
.fs-18 {
  font-size: 1.8em;
}
.fs-19 {
  font-size: 1.9em;
}
.fs-20 {
  font-size: 2em;
}
.fs-25 {
  font-size: 2.5em;
}
.fs-30 {
  font-size: 3em;
}
.fs-35 {
  font-size: 3.5em;
}
.fs-40 {
  font-size: 4em;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

.main-color {
  color: #ff5252;
}

.font-color {
  color: rgba(255, 255, 255, 0.87);
}

.font-faded {
  color: rgba(255, 255, 255, 0.6);
}

.light-gray {
  color: #8e8e8e;
}

.green {
  color: #52ff82;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.bb-1 {
  border-bottom: 1px dashed currentColor;
}

table.simple {
  width: 100%;
}
table.simple td {
  font-weight: 500;
  padding: 0.5em 0;
  line-height: 125%;
}
table.simple td:first-child {
  color: rgba(255, 255, 255, 0.6);
  padding-right: 1em;
}
table.simple td:last-child {
  color: rgba(255, 255, 255, 0.87);
  text-align: right;
}

.box {
  background: #121212;
  border-radius: 1em;
  border: 1px solid #1f1f1f;
  padding: 2em 3em;
}
@media (max-width: 1199px) {
  .box {
    padding: 2em;
  }
}
@media (max-width: 575px) {
  .box {
    padding: 1em;
  }
}
.box hr {
  height: 1px;
  width: 100%;
  background-color: #1f1f1f;
  border: none;
  opacity: 1;
  margin: 1.5em 0;
}

.flex-1 {
  flex: 1;
}

.mb-6 {
  margin-bottom: 6em;
}
@media (max-width: 767px) {
  .mb-6 {
    margin-bottom: 5em;
  }
}
@media (max-width: 575px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

@media (max-width: 575px) {
  .px-mobile-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
body {
  margin: 0;
  font-family: "Comfortaa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 400;
  --h-height: 90px;
  --f-height: 230px;
}
@media (max-width: 1199px) {
  body {
    font-size: 14px;
    --h-height: 70px;
    --f-height: 200px;
  }
}
@media (max-width: 991px) {
  body {
    --f-height: 65px;
    padding-top: var(--h-height);
    padding-top: var(--f-height);
  }
}
@media (max-width: 767px) {
  body {
    --h-height: 60px;
  }
}

header {
  background: #1f1f1f;
  height: var(--h-height);
  position: relative;
  z-index: 1050;
}
@media (max-width: 991px) {
  header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
  }
}
header.h-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
  z-index: 100;
}
header.h-fixed.show {
  display: block;
}
@media (max-width: 991px) {
  header.h-fixed {
    top: var(--h-height);
  }
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .container nav {
  font-size: 0.9em;
}
header .container nav ul {
  padding-left: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .container nav ul li:not(:first-child) {
  margin-left: 2em;
}
header .container .fav {
  position: relative;
}
header .container .fav svg {
  font-size: 1.5em;
  color: #ff5252;
}
header .container .fav span {
  position: absolute;
  z-index: 2;
  top: -50%;
  right: -85%;
  font-size: 0.8em;
  font-weight: 700;
  width: 2em;
  height: 2em;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
header .container .fav span:empty {
  display: none;
}

.search-desc b {
  color: #ff5252;
}

.form-search {
  display: flex;
  position: relative;
  z-index: 999;
  align-items: center;
}
.form-search input {
  flex: 1;
}

main {
  min-height: 100vh;
  padding: 3em 0;
}

/* Profile */
input[type=date]::-webkit-calendar-picker-indicator,
input[type=time]::-webkit-calendar-picker-indicator,
input[type=datetime-local]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

#menu {
  position: relative;
  z-index: 999;
}

/* Account Menu */
.account-info {
  min-height: 100%;
}

.account-nav {
  position: relative;
  z-index: 9999;
  padding: 1em 0px;
  border: 1px solid #181818;
  border-radius: 1em;
}
@media (max-width: 991px) {
  .account-nav {
    padding: 0px;
  }
}
.account-nav ul {
  list-style: none;
  padding-left: 0px;
}
.account-nav ul li a,
.account-nav ul li button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.6em 1.4em;
  position: relative;
  overflow: hidden;
  font-size: 1.2em;
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #181818;
}
@media (max-width: 991px) {
  .account-nav ul li a,
.account-nav ul li button {
    font-size: 1em;
  }
}
@media (max-width: 575px) {
  .account-nav ul li a,
.account-nav ul li button {
    padding: 0.8em;
    padding-right: 0.2em;
  }
}
.account-nav ul li a.active,
.account-nav ul li button.active {
  color: #ff5252;
  background-color: #1f1f1f;
  border-color: transparent;
}
.account-nav ul li a.active::before,
.account-nav ul li button.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2.5px;
  bottom: 0;
  width: 5px;
  background-color: #ff5252;
}
.account-nav ul li a svg,
.account-nav ul li button svg {
  color: inherit;
}
.account-nav ul li a svg polyline,
.account-nav ul li button svg polyline {
  stroke: inherit;
}
.account-nav ul li:last-child a, .account-nav ul li:last-child button {
  border: 0px;
}
.account-nav ul li button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 1.5em;
  right: 1.5em;
  height: 1px;
  background-color: #dadada;
}
@media (max-width: 575px) {
  .account-nav ul li button::after {
    left: 0px;
    right: 0px;
  }
}
.account-nav .logout::after {
  display: none;
}
@media (max-width: 991px) {
  .account .box-2 {
    box-shadow: none;
    border-radius: unset;
  }
}

.swiper-button-disabled {
  display: none;
}
.swiper-button-prev {
  left: 0px;
  background: linear-gradient(to right, #121212 20%, transparent);
}
.swiper-button-next {
  right: 0px;
  background: linear-gradient(to left, #121212 20%, transparent);
}
.swiper-button-prev, .swiper-button-next {
  top: 0px;
  bottom: 0px;
  width: 8%;
  height: 100%;
  margin-top: 0px;
  color: rgba(255, 255, 255, 0.87);
}
.swiper-button-prev::after, .swiper-button-next::after {
  font-weight: 800;
  font-size: 1.4em;
}

.swiper-stories .swiper-slide {
  width: 270px;
}
@media (max-width: 991px) {
  .swiper-stories .swiper-slide {
    width: 100px;
  }
}
@media (max-width: 575px) {
  .swiper-stories .swiper-slide {
    width: 70px;
  }
}

figure.story {
  position: relative;
  width: 100%;
  border: 1px solid #ff5252;
  border-radius: 1.5em;
  padding: 0.5em;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 991px) {
  figure.story {
    border: none;
    border-radius: 0px;
    padding: 0px;
  }
}
figure.story img {
  width: 100%;
  border-radius: 1.25em;
  height: 340px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 991px) {
  figure.story img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #ff5252;
    padding: 4px;
  }
}
@media (max-width: 575px) {
  figure.story img {
    width: 70px;
    height: 70px;
  }
}
figure.story figcaption {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  bottom: 0.5em;
  right: 0.5em;
  background-image: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(18, 18, 18, 0) 0.01%, #121212 100%), radial-gradient(ellipse farthest-side at right top, #121212, transparent);
  background-position: center bottom, center top;
  background-size: 100% 45%, 100% 30%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
}
@media (max-width: 991px) {
  figure.story figcaption {
    position: relative;
    padding: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin-top: 0.5em;
  }
  figure.story figcaption h3 {
    font-size: 1em;
    font-weight: 400;
    text-align: center;
  }
}
@media (max-width: 575px) {
  figure.story figcaption h3 {
    font-size: 0.9em;
  }
}

.swiper-stories-mobile {
  width: 100%;
  border-radius: 10px;
  position: relative;
}
.swiper-stories-mobile .swiper-pagination {
  bottom: unset;
  top: 1em;
  height: 10px;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-stories-mobile .swiper-pagination-bullet {
  background-color: #8e8e8e;
  transition: 0.3s;
  opacity: 1;
  flex-grow: 1;
  height: 3px;
  border-radius: 1px;
}
.swiper-stories-mobile .swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.87);
  opacity: 1;
}

figure.story-large {
  position: relative;
  width: 100%;
  padding: 0px;
  overflow: hidden;
}
figure.story-large img {
  width: 100%;
  height: 800px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1399px) {
  figure.story-large img {
    height: 700px;
  }
}
@media (max-width: 575px) {
  figure.story-large img {
    height: 99vh;
  }
}
figure.story-large figcaption {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 15%, transparent 40%, #121212 100%);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2em;
}
figure.story-large figcaption .content {
  display: grid;
  grid-template-columns: auto 110px;
  grid-template-rows: auto 110px;
}
@media (max-width: 575px) {
  figure.story-large figcaption .content {
    grid-template-rows: auto auto;
  }
}
figure.story-large figcaption .content .info {
  grid-row: 1/2;
  grid-column: 1/2;
}
figure.story-large figcaption .content .ingredients {
  grid-row: 2/3;
  grid-column: 1/2;
  padding-right: 1em;
}
@media (max-width: 575px) {
  figure.story-large figcaption .content .ingredients {
    grid-column: 1/3;
  }
}
figure.story-large figcaption .content .g-btn {
  grid-row: 2/3;
  grid-column: 2/3;
}
@media (max-width: 575px) {
  figure.story-large figcaption .content .g-btn {
    grid-row: 1/2;
  }
}
figure.story-large figcaption .content .g-btn .btn-2 {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  padding: 0px;
}

.story-modal .modal-dialog {
  max-width: 565px;
}
@media (max-width: 1399px) {
  .story-modal .modal-dialog {
    max-width: 494px;
  }
}
@media (max-width: 575px) {
  .story-modal .modal-dialog {
    max-width: 100%;
  }
}

.swiper-menu {
  background-color: #1f1f1f;
  padding: 1em;
  border-radius: 1em;
}
@media (max-width: 575px) {
  .swiper-menu {
    border-radius: 0px;
  }
}
.swiper-menu .swiper-button-prev {
  background: linear-gradient(to right, #1f1f1f 60%, transparent);
  width: 4.5em;
}
.swiper-menu .swiper-button-next {
  background: linear-gradient(to left, #1f1f1f 60%, transparent);
  width: 4.5em;
}
.swiper-menu .swiper-slide {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
}
.swiper-menu .swiper-slide button,
.swiper-menu .swiper-slide a {
  padding: 0.6em 1.2em;
  border-radius: 10px;
  font-weight: 500;
}
.swiper-menu .swiper-slide button:hover, .swiper-menu .swiper-slide button:focus,
.swiper-menu .swiper-slide a:hover,
.swiper-menu .swiper-slide a:focus {
  background-color: #121212;
}
.swiper-menu .swiper-slide button:active, .swiper-menu .swiper-slide button.active,
.swiper-menu .swiper-slide a:active,
.swiper-menu .swiper-slide a.active {
  background-color: #121212;
  color: #ff5252;
}

.product-item figure {
  position: relative;
  margin-bottom: 1em;
}
.product-item figure img,
.product-item figure div.img {
  height: 290px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1em;
  margin-bottom: 1em;
}
@media (max-width: 1399px) {
  .product-item figure img,
.product-item figure div.img {
    height: 260px;
  }
}
@media (max-width: 1199px) {
  .product-item figure img,
.product-item figure div.img {
    height: 220px;
  }
}
@media (max-width: 767px) {
  .product-item figure img,
.product-item figure div.img {
    height: 210px;
  }
}
@media (max-width: 575px) {
  .product-item figure img,
.product-item figure div.img {
    height: 42vw;
  }
}
.product-item figure figcaption {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  height: 3em;
}
.product-item .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1199px) {
  .product-item .info {
    font-size: 13px;
  }
}
@media (max-width: 575px) {
  .product-item .info {
    flex-direction: column-reverse;
  }
  .product-item .info > * {
    width: 100%;
  }
}
.product-item .info .price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.product-item .info .price del {
  font-size: 0.8em;
  color: #8e8e8e;
  font-weight: 600;
  margin-right: 0.5em;
}
@media (max-width: 1399px) {
  .product-item .info .price {
    flex-direction: column;
  }
  .product-item .info .price del {
    margin-right: 0px;
  }
}
@media (max-width: 575px) {
  .product-item .info .price {
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }
  .product-item .info .price del {
    margin-right: 1em;
  }
}

.product-full-img {
  position: relative;
  border-radius: 1em;
  overflow: hidden;
}
.product-full-img img {
  width: 100%;
  position: relative;
  z-index: 1;
  height: 510px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1399px) {
  .product-full-img img {
    height: 435px;
  }
}
@media (max-width: 1199px) {
  .product-full-img img {
    height: 360px;
  }
}
@media (max-width: 991px) {
  .product-full-img img {
    height: 275px;
  }
}
@media (max-width: 767px) {
  .product-full-img img {
    height: 515px;
  }
}
@media (max-width: 575px) {
  .product-full-img img {
    height: 90vw;
  }
}
.product-full-img figcaption {
  position: absolute;
  top: 1.5em;
  left: 1.5em;
  z-index: 5;
  color: #ff5252;
  font-weight: 500;
  font-size: 1.1em;
}
.product-full form.btns {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .product-full form.btns {
    justify-content: flex-start;
    margin-top: 1em;
  }
}
@media (max-width: 575px) {
  .product-full form.btns {
    width: 100%;
  }
}
.product-full form.btns button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-full form.btns button.center {
  border: 1px solid currentColor;
  color: #ff5252;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  font-weight: 700;
  transition: 0.3s;
}
@media (max-width: 1199px) {
  .product-full form.btns button.center {
    font-size: 0.9em;
    width: 6em;
    height: 6em;
  }
}
@media (max-width: 991px) {
  .product-full form.btns button.center {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0.6em;
    width: 10em;
    padding: 0.5em;
    font-size: 1em;
  }
}
@media (max-width: 575px) {
  .product-full form.btns button.center {
    flex: 1;
    width: 100%;
  }
}
.product-full form.btns button.center:hover, .product-full form.btns button.center:focus, .product-full form.btns button.center:active {
  color: #bf3535;
}
.product-full form.btns button.edge {
  font-size: 1.5em;
  color: #8e8e8e;
  transition: 0.3s;
}
@media (max-width: 575px) {
  .product-full form.btns button.edge {
    padding: 0.5em;
  }
}
.product-full form.btns button.edge:hover, .product-full form.btns button.edge:focus, .product-full form.btns button.edge:active {
  color: rgba(255, 255, 255, 0.87);
}

.cart-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 50px;
  gap: 10px 30px;
  margin-bottom: 3em;
}
@media (max-width: 575px) {
  .cart-item {
    gap: 10px 10px;
  }
}
.cart-item .img {
  grid-column: 1/2;
  grid-row: 1/3;
}
@media (max-width: 575px) {
  .cart-item .img {
    grid-row: 1/2;
  }
}
.cart-item .img img {
  width: 100%;
  height: 170px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 1em;
}
@media (max-width: 575px) {
  .cart-item .img img {
    height: 25vw;
  }
}
.cart-item .text {
  grid-column: 2/3;
  grid-row: 1/2;
}
.cart-item .text .btn-del {
  color: #8e8e8e;
  font-size: 1.5em;
}
.cart-item .controls {
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575px) {
  .cart-item .controls {
    grid-column: 1/3;
  }
}
.cart-item .controls .input-box {
  background-color: #1f1f1f;
  border-radius: 0.6em;
  padding: 0.85em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-item .controls .input-box input {
  width: 80px;
  padding: 0px;
  text-align: center;
  font-weight: 700;
}
.cart-item .controls .input-box button {
  display: flex;
}

.product-cart {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 1.2em 1em;
}
.product-cart:not(:first-child) {
  border-top: 1px solid #1f1f1f;
}
@media (max-width: 575px) {
  .product-cart {
    flex-wrap: wrap;
    padding: 1em 0px;
  }
  .product-cart > div {
    width: 100%;
  }
}
.product-cart img {
  width: 55px;
  height: 55px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.6em;
}
@media (max-width: 575px) {
  .product-cart img {
    width: 45px;
    height: 45px;
  }
}

.order {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 1em;
  margin-top: 1em;
}
@media (max-width: 991px) {
  .order {
    font-size: 0.9em;
  }
}
.order .main {
  display: flex;
  align-items: center;
  padding: 1em;
}
.order .main .title {
  flex: 1;
  font-size: 1.25em;
  font-weight: 600;
  text-align: left;
}
.order .main .date {
  text-align: center;
  margin-left: 1em;
  width: 150px;
}
@media (max-width: 1199px) {
  .order .main .date {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .order .main .date {
    width: 50px;
    margin-left: 0.5em;
    font-size: 0.75em;
  }
}
.order .main .sum {
  text-align: center;
  margin-left: 1em;
  width: 150px;
}
@media (max-width: 1199px) {
  .order .main .sum {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .order .main .sum {
    width: 70px;
    margin-left: 0.5em;
  }
}
@media (max-width: 575px) {
  .order .main .sum {
    display: none;
  }
}
.order .main .status {
  text-align: center;
  margin-left: 1em;
  width: 200px;
}
@media (max-width: 991px) {
  .order .main .status {
    width: 160px;
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .order .main .status {
    width: 125px;
    margin-left: 0.5em;
  }
}
@media (max-width: 575px) {
  .order .main .status {
    display: none;
  }
}
.order .main .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
.order .main .btns button {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}
.order .main .btns button:hover, .order .main .btns button:focus {
  color: #ff5252;
}
.order .main .btns button:active {
  color: #bf3535;
}
.order .extended {
  border-top: 1px solid #000;
  padding: 1em;
}
.order .extended .product-short {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.order .extended .product-short .name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delivery .icon, .delivery .btn-fav .icon-stroke, .btn-fav .delivery .icon-stroke, .delivery .btn-fav .icon-fill, .btn-fav .delivery .icon-fill {
  width: 3.75em;
  height: 3.75em;
}

.about .icon, .about .btn-fav .icon-stroke, .btn-fav .about .icon-stroke, .about .btn-fav .icon-fill, .btn-fav .about .icon-fill {
  width: 6em;
}
@media (max-width: 1199px) {
  .about .icon, .about .btn-fav .icon-stroke, .btn-fav .about .icon-stroke, .about .btn-fav .icon-fill, .btn-fav .about .icon-fill {
    width: 4em;
  }
}
@media (max-width: 991px) {
  .about .icon, .about .btn-fav .icon-stroke, .btn-fav .about .icon-stroke, .about .btn-fav .icon-fill, .btn-fav .about .icon-fill {
    width: 8em;
  }
}
@media (max-width: 575px) {
  .about .icon, .about .btn-fav .icon-stroke, .btn-fav .about .icon-stroke, .about .btn-fav .icon-fill, .btn-fav .about .icon-fill {
    width: 3em;
  }
}
.about .text {
  color: rgba(255, 255, 255, 0.6);
}
.about .text p:not(:first-child) {
  margin-top: 1em;
}

footer {
  background: #1f1f1f;
  height: var(--f-height);
}
footer .dev {
  color: #8e8e8e;
  font-weight: 500;
  font-size: 0.8em;
}
footer .dev .color {
  color: #b2ff59;
  font-weight: 600;
}

.mobile-nav {
  background: #1f1f1f;
  height: var(--f-height);
  width: 100%;
}
@media (max-width: 991px) {
  .mobile-nav {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    border-radius: 1em 1em 0 0;
  }
}
@media (min-width: 992px) {
  .mobile-nav {
    display: none;
  }
}
.mobile-nav ul {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-nav ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  width: 1.9em;
  height: 1.9em;
  border: 2px solid transparent;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  position: relative;
}
.mobile-nav ul li a::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: transparent;
  transition: 0.3s;
  transform: translate(-50%, 50%);
}
.mobile-nav ul li a.active {
  color: #ff5252;
  border: 2px solid #303030;
}
.mobile-nav ul li a.active::before {
  background-color: #ff5252;
}

.empty-page {
  height: 100%;
  text-align: center;
}
.empty-page section {
  height: 100%;
}

.modal {
  z-index: 99999;
}
.modal-backdrop {
  z-index: 99999;
}
@media (max-width: 575px) {
  .modal-dialog {
    margin: 0px !important;
    width: 100%;
  }
}
.modal-content {
  background-color: #121212;
  border-radius: 1em;
}
@media (max-width: 575px) {
  .modal-content {
    height: 100%;
  }
}
.modal-footer {
  border-color: #1f1f1f;
}
.modal-header {
  display: block;
  border-bottom: 1px solid #1f1f1f;
  padding: 1.5em 1.8em;
  position: relative;
}
@media (max-width: 767px) {
  .modal-header .close {
    top: 50% !important;
  }
}
@media (max-width: 575px) {
  .modal-header {
    padding: 1em;
  }
}
.modal-body {
  position: relative;
  padding: 1.8em;
}
@media (max-width: 575px) {
  .modal-body {
    padding: 1em;
  }
}
.modal .close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 95%;
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  font-size: 2em;
  transition: 0.3s;
  z-index: 100;
}
.modal .close:hover, .modal .close:focus {
  color: #ff5252;
  opacity: 1;
}
@media (max-width: 767px) {
  .modal .close {
    left: unset;
    bottom: unset;
    right: 0.5em;
    top: 2em;
    transform: translateY(-50%);
  }
}

form.login-forms {
  width: 300px;
  max-width: 100%;
}

.login {
  display: flex;
  height: calc(100vh - 220px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offcanvas {
  background-color: #121212;
}
.offcanvas.offcanvas-start {
  top: var(--h-height);
  width: 100%;
  z-index: 99999;
}
.offcanvas.offcanvas-top {
  top: var(--h-height);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.offcanvas-body {
  padding: 2em 0;
}

.mobile-menu-left {
  font-size: 1.2em;
}
.mobile-menu-left a {
  color: rgba(255, 255, 255, 0.87);
  transition: 0.3s;
}
.mobile-menu-left a.active {
  color: #ff5252;
}

.phone-input:focus,
.form-control:focus {
  background: #1f1f1f;
  color: rgba(255, 255, 255, 0.87);
  box-shadow: none;
}

.action-alert {
  position: fixed;
  right: 2vw;
  top: 12vh;
  background-color: #1f1f1f;
  max-width: 300px;
  padding: 15px;
  font-size: 0.8em;
  z-index: 3000;
  border-radius: 10px;
  display: none;
  align-items: center;
  gap: 10px;
  -webkit-animation: linear;
          animation: linear;
  -webkit-animation-name: run;
          animation-name: run;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}
.action-alert.show {
  display: flex;
}
.action-alert__icon {
  width: 20px;
  height: 20px;
  flex: 1 0 auto;
}
.action-alert_success .action-alert__icon {
  color: #52ff82;
}
.action-alert_danger .action-alert__icon {
  color: #bf3535;
}
@-webkit-keyframes run {
  0% {
    right: -300px;
  }
  100% {
    right: 2vw;
  }
}
@keyframes run {
  0% {
    right: -300px;
  }
  100% {
    right: 2vw;
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #121212;
}

.custom-table {
  overflow: hidden;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}
.custom-table nav {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.custom-table nav svg {
  top: 25%;
  bottom: 0;
}
.custom-table div {
  scrollbar-color: rgba(var(--scroll-bg)/0.75) transparent;
  scrollbar-width: thin;
}
.custom-table div::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;
}
.custom-table div::-webkit-scrollbar:hover, .custom-table div::-webkit-scrollbar:active {
  background: transparent;
}
.custom-table .cpdasa button {
  border-radius: 0px;
}
.custom-table div::-webkit-scrollbar-thumb {
  background-color: rgba(var(--scroll-bg)/0.5);
  border-radius: 3px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.custom-table div::-webkit-scrollbar-thumb:hover {
  background-color: rgba(var(--scroll-bg)/0.75);
}
.custom-table div::-webkit-scrollbar-thumb:active {
  background-color: rgba(var(--scroll-bg)/1);
}
.custom-table div::-webkit-scrollbar-button:vertical:start:decrement,
.custom-table div::-webkit-scrollbar-button:vertical:end:increment,
.custom-table div::-webkit-scrollbar-button:horizontal:start:decrement,
.custom-table div::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

.products__inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.products__button:active {
  background-color: #ff5252;
}
.products__button:first-child:active {
  background-color: #ff5252;
}

.custom-input-street {
  inset: auto !important;
  transform: none !important;
}

.pagination {
  display: flex;
  align-items: center;
  list-style: none;
}
.pagination li {
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #bf3535;
  background: transparent;
  cursor: pointer;
}
@media (max-width: 575px) {
  .pagination li {
    width: 45px;
    height: 45px;
  }
}
.pagination li:hover {
  background: rgba(80, 80, 80, 0.3);
}
.pagination li.selected {
  background-color: #bf3535;
}
.pagination li.selected a {
  color: #fff;
}
.pagination .previous {
  border-radius: 14px 0 0 14px;
}
.pagination .previous::before {
  content: "<";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pagination a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pagination .next {
  border-radius: 0 14px 14px 0;
}
.pagination .next::before {
  content: ">";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.favorites section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 50px;
}
.product-micro-img {
  width: 35px;
  height: 35px;
  -o-object-fit: cover;
     object-fit: cover;
}

.min-h-30 {
  min-height: 30vh !important;
}

.cart-count-mobile {
  font-size: 10px;
  min-width: 17px;
  height: 17px;
  background-color: #cb3030;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  align-content: center;
  position: absolute;
  bottom: 3px;
  right: 2px;
}

.w-fit {
  cursor: pointer;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.admin-order-photo {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-right: 10px;
  border-radius: 10px;
}/*# sourceMappingURL=style.css.map */